import React, {useEffect, useState, useRef}                         from "react"
import { CButton, CModal, CModalBody, CModalFooter, CModalHeader }  from '@coreui/react'
import { useHistory }                                               from 'react-router-dom'

const EVENTS = [
  'keypress',
  'keydown',
  'mousemove',
  'mousedown',
  'scroll',
  'touchmove',
  'pointermove'
]

const SessionTimeoutWarning = ({ timeout, active }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const timeoutRef = useRef()
  const history = useHistory()
  const logout = () => {
    history.push('/login')
    hideModal()
  }
  const showModal = () => {
    setIsModalVisible(true)
  }
  const hideModal = () => {
    setIsModalVisible(false)
  }
  useEffect(() => {
    const setupInterval = () => {
      clearInterval(timeoutRef.current)
      timeoutRef.current = setInterval(() => { showModal() }, timeout / 1.25)
    }
    const addEventListeners = (events) => {
      events.forEach((event) => {
        window.addEventListener(event, setupInterval)
      })
    }
    const removeEventListeners = (events) => {
      events.forEach((event) => {
        window.removeEventListener(event, setupInterval)
      })
    }
    if(active) {
      setupInterval()
      addEventListeners(EVENTS)
    }
    return () => {
      clearInterval(timeoutRef.current)
      removeEventListeners(EVENTS)
    }
  }, [EVENTS, active])
  return (
    <>
      <CModal
        visible={ isModalVisible }
        show={ isModalVisible }
        onClose={ hideModal }>
        <CModalHeader closeButton>You Have Been Idle!</CModalHeader>
        <CModalBody>
          { `Your session is about to expire. Do you want to extend your session?` }
        </CModalBody>
        <CModalFooter>
          <CButton color="primary" onClick={ hideModal }>Extend session</CButton>{' '}
          <CButton color="secondary" onClick={ logout }>Logout</CButton>
        </CModalFooter>
      </CModal>
    </>
  )
}

export default React.memo(SessionTimeoutWarning)
