import React, { useState }   from "react"

const SelectedDashboardUserContext = React.createContext({
  selectedDashboardUser: null, setSelectedDashboardUser: () => {}
})

const SelectedDashboardUserContextProvider = ({ children }) => {
  const [selectedDashboardUser, setSelectedDashboardUser] = useState()
  const value = { selectedDashboardUser, setSelectedDashboardUser }
  return (
    <SelectedDashboardUserContext.Provider value={ value }>
      { children }
    </SelectedDashboardUserContext.Provider>
  )
}

export {
  SelectedDashboardUserContext,
  SelectedDashboardUserContextProvider,
}
