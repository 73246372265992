import axios from 'axios'

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 30000,
})

axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (errData) => {
    if (errData?.response?.status === 401) {
      window.location.href = '/login'
    }
    return Promise.reject(errData)
  },
)

export { axiosInstance }
