import React, { useEffect, useState, useRef } from "react"
import { useHistory }                         from 'react-router-dom'

const EVENTS = [
  'keypress',
  'keydown',
  'mousemove',
  'mousedown',
  'scroll',
  'touchmove',
  'pointermove'
]

const SessionTimeout = ({ timeout, active }) => {
  const timeoutRef = useRef()
  const history = useHistory()
  const logout = () => {
    history.push('/login')
  }
  useEffect(() => {
    const setupTimeout = () => {
      clearInterval(timeoutRef.current)
      timeoutRef.current = setTimeout(() => { logout() }, timeout)
    }
    const addEventListeners = (events) => {
      events.forEach((event) => {
        window.addEventListener(event, setupTimeout)
      })
    }
    const removeEventListeners = (events) => {
      events.forEach((event) => {
        window.removeEventListener(event, setupTimeout)
      })
    }
    if(active) {
      setupTimeout()
      addEventListeners(EVENTS)
    }
    return () => {
      clearTimeout(timeoutRef.current)
      removeEventListeners(EVENTS)
    }
  }, [EVENTS, active, logout])
  return (<></>)
}

export default React.memo(SessionTimeout)
