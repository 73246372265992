import React, {useState, useEffect}  from "react"
import { getUserRole }               from 'src/api/user'


const RoleContext = React.createContext({
  role: null, setRole: () => {}
})

const RoleContextProvider = ({ children }) => {
  const [role, setRole] = useState()
  const value = { role, setRole }

  useEffect(() => {
    getUserRole()
      .then((res) => {
        setRole(res?.tier)
      })
      .catch((err) => {
        console.error(err)
      })
  }, [])

  return (
    <RoleContext.Provider value={ value }>
      { children }
    </RoleContext.Provider>
  )
}

export {
  RoleContext,
  RoleContextProvider,
}
