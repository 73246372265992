import React, { useState, useEffect }   from "react"
import { CONSTANTS }                    from "src/constants"

const SelectedUserContext = React.createContext({
  selectedUser: null, setSelectedUser: () => {}
})

const SelectedUserContextProvider = ({ children }) => {
  const [selectedUser, setSelectedUser] = useState()
  const value = { selectedUser, setSelectedUser }
  useEffect(() => {
    setSelectedUser(localStorage.getItem(CONSTANTS.SELECTED_USER))
  }, [])
  return (
    <SelectedUserContext.Provider value={ value }>
      { children }
    </SelectedUserContext.Provider>
  )
}

export {
  SelectedUserContext,
  SelectedUserContextProvider,
}
