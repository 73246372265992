import React                            from 'react'
import {
  BrowserRouter,
  Route,
  Switch,
}                                       from 'react-router-dom'
import IdleSession                      from 'src/components/IdleSession'
import { RoleContextProvider }          from 'src/context/RoleContext'
import { SelectedUserContextProvider }  from 'src/context/SelectedUserContext'
import { 
  SelectedDashboardUserContextProvider 
}                                       from './context/SelectedDashboardUserContext'
import { UserContextProvider }          from 'src/context/UserContext'
import './scss/style.scss'


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const QrDownload = React.lazy(() => import('./views/pages/qrDownload/QrDownload'))

const App = () => {
  return (
    <BrowserRouter>
      <UserContextProvider>
          <SelectedUserContextProvider>
            <React.Suspense fallback={loading}>
              <Switch>
                <Route
                  exact
                  path="/qrdownload/:serialNumber"
                  name="Pass download"
                  render={(props) => <QrDownload {...props} />}
                />
                <Route
                  exact
                  path="/login"
                  name="Login Page"
                  render={(props) => <Login {...props} />}
                />
                <Route
                  exact
                  path="/register"
                  name="Register Page"
                  render={(props) => <Register {...props} />}
                />
                <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
                <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
                <RoleContextProvider>
                  <SelectedDashboardUserContextProvider>
                    <Route
                      path="/"
                      name="Home" render={(props) => <DefaultLayout {...props} />}
                    />
                  </SelectedDashboardUserContextProvider>
                </RoleContextProvider>
              </Switch>
            </React.Suspense>
          </SelectedUserContextProvider>
        <IdleSession />
      </UserContextProvider>
    </BrowserRouter>
  )
}

export default App
