import { axiosInstance } from './base'

export const login = async (username, password) => {
  const res = await axiosInstance({
    method: 'post',
    url: '/user/login',
    data: {
      username: username,
      password: password,
    },
  })
  return res.data
}

export const getUserList = async () => {
  const res = await axiosInstance({
    method: 'post',
    url: '/user/list',
    headers: {
      'Content-Type': 'application/json',
      'Auth-Token': localStorage.getItem('USER_TOKEN'),
    },
  })
  return res.data
}

export const getUserRole = async () => {
  const res = await axiosInstance({
    method: 'post',
    url: '/user/tier',
    headers: {
      'Content-Type': 'application/json',
      'Auth-Token': localStorage.getItem('USER_TOKEN'),
    },
  })
  return res.data
}

export const getUser = async (data) => {
  const res = await axiosInstance({
    method: 'post',
    url: `/user/view`,
    headers: {
      'Content-Type': 'application/json',
      'Auth-Token': localStorage.getItem('USER_TOKEN'),
    },
    data: data,
  })
  return res.data
}

export const createUser = async (data) => {
  const res = await axiosInstance({
    method: 'post',
    url: '/user/create',
    headers: {
      'Content-Type': 'application/json',
      'Auth-Token': localStorage.getItem('USER_TOKEN'),
    },
    data: data,
  })
  return res.data
}

export const updateUser = async (data) => {
  const res = await axiosInstance({
    method: 'post',
    url: '/user/update',
    headers: {
      'Content-Type': 'application/json',
      'Auth-Token': localStorage.getItem('USER_TOKEN'),
    },
    data: data,
  })
  return res.data
}

export const updateUserCerts = async (formData) => {
  const res = await axiosInstance({
    method: 'post',
    url: 'user/upload_certificates',
    headers: {
      'Content-Type': 'multipart/form-data',
      'Auth-Token': localStorage.getItem('USER_TOKEN'),
    },
    data: formData,
  })
  return res.data
}

export const deleteUser = async (data) => {
  const res = await axiosInstance({
    method: 'post',
    url: `/user/delete`,
    headers: {
      'Content-Type': 'application/json',
      'Auth-Token': localStorage.getItem('USER_TOKEN'),
    },
    data: data,
  })
  return res.data
}
