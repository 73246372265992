import PropTypes      from 'prop-types'
import React, {
  createContext,
  useEffect,
  useState,
}                     from 'react'
import {
  useHistory,
  useLocation,
}                     from 'react-router-dom'
import { CONSTANTS }  from "src/constants"

const UserContext = createContext({
  currentUser: null, setCurrentUser: () => {}
})

const UserContextProvider = ({ children }) => {
  const history = useHistory()
  const location = useLocation()

  const [currentUser, setCurrentUser] = useState()
  const value = { currentUser, setCurrentUser }

  useEffect(() => {
    setCurrentUser(localStorage.getItem(CONSTANTS.CURRENT_USER))
  }, [])

  useEffect(() => {
    const publicPages = ['/qrdownload', '/login', '/404', '/500']
    if (location.hash.startsWith('#/')) {
      window.location.replace(location.hash.replace(/#\/(.*)/, '$1'))
    } else if (!localStorage.getItem('USER_TOKEN') && publicPages.every(e => !location.pathname.startsWith(e))) {
      history.push({ pathname: '/login', from: location.pathname })
    }
  }, [location, history])

  return (
    <UserContext.Provider
      value={ value }
    >
      { children }
    </UserContext.Provider>
  )
}

UserContextProvider.propTypes = {
  children: PropTypes.node,
}

export {
  UserContext,
  UserContextProvider,
}
