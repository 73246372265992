import React, { useEffect, useState, useRef } from "react"
import { useLocation }                        from 'react-router-dom'
import SessionTimeout                         from 'src/components/SessionTimeout'
import SessionTimeoutWarning                  from 'src/components/SessionTimeoutWarning'

const TIMEOUT = 1000 * 60 * 30

const IdleSession = () => {
    const [active, setActive] = useState(false)
    const location = useLocation()
    useEffect(() => {
        const publicPages = ['/qrdownload', '/login', '/404', '/500']
        setActive(localStorage.getItem('USER_TOKEN') && publicPages.every(e => !location.pathname.startsWith(e)))
    }, [location])
    return (
        <>
            <SessionTimeout timeout={TIMEOUT} active={active} />
            <SessionTimeoutWarning timeout={TIMEOUT} active={active} />
        </>
    )
}

export default IdleSession
